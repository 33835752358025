$( document ).ready(function() {

//---------------FICHA DE PRODUCTO: POP_UP descargar PDF--------------------------

        var idPageDownload = 0; 
        var idFieldDownload = 0; 
    
        $('.action-pdf').on('click', function(event){
            event.preventDefault();
            console.log("holi");
            $('.seleccion_idiomas_pdf').fadeIn('slow');
            $('BODY').addClass('no-scroll');
            idPageDownload = $(this).attr("data-id-page-download");
            idFieldDownload = $(this).attr("data-id-field-download");
            return false;
        });
     
        $('.btn-close').on('click', function(event){
            event.preventDefault();
            $('.seleccion_idiomas_pdf').fadeOut('slow');
            $('BODY').removeClass("no-scroll");
            return false;
        });
    
        $('.action-descarga-pdf').on('click', function(){
            $('.seleccion_idiomas_pdf').fadeOut('slow');
            $('BODY').removeClass("no-scroll");
            window.open('/es/api/download-pdf/'+idPageDownload+'/'+idFieldDownload+'/'+$('SELECT#idiomas').val(), '_blank'); 
            return false;
        });
        

//---------------FICHA DE PRODUCTO: POP_UP descargar PDF ficha de seguridad-------------------------


        var idPageDownload = 0; 
        var idFieldDownload = 0; 
    
        $('.action-popup-descarga').on('click', function(event){
            event.preventDefault();
            $('.section-form-descargas').fadeIn('slow');
            $('BODY').addClass('no-scroll');
            idPageDownload = $(this).attr("data-id-page-download");
            idFieldDownload = $(this).attr("data-id-field-download");
            return false;
        });
     
        $('.btn-close').on('click', function(event){
            event.preventDefault();
            $('.section-form-descargas').fadeOut('slow');
            $('BODY').removeClass("no-scroll");
            return false;
        });
    
        $( "FORM#form-descarga-pdf-security" ).submit(function( event ) {
            event.preventDefault();
            var dataString = $( "FORM#form-descarga-pdf-security" ).serialize();
            console.log(dataString);
            $.ajax({
              type: "POST",
              url: "/es/api/save-data-pdf-security/",
              data: dataString,
              success: function(data)
              {
                if ( data == "ok" ) { 
                    $('.section-form-descargas').fadeOut('slow');
                    $('BODY').removeClass("no-scroll");
                    window.open('/es/api/download-pdf-security/'+idPageDownload+'/'+$('SELECT#idiomas-security').val(), '_blank'); 
                } else {
                    alert( "error" );
                }
              }
            });
        });

//  ------------------------- Filtro listados PDF idiomas--------------------------


    $("A.idioma").on("click", function(e) {
        e.preventDefault(); 
        $("A.idioma").removeClass("active-idioma");
        $(this).addClass("active-idioma");
    });

    $( "a.never-active" ).addClass( "never-active" );





//  ------------------------- Buton CTA--------------------------

    $(".icono").click(function(event) {
        event.preventDefault();
    });

    $(".objeto-desplegable").hover(function(event) {
        event.preventDefault();
        $( ".wrap-texto" ).fadeIn(300);
    }, function() {
        $( ".wrap-texto" ).fadeOut(300);
    });   
    
    $( window ).scroll(function() {
        $( ".wrap-texto" ).fadeOut(300);
    });

    /*

    $(function(){
        $(".objeto-desplegable").hover(function() {
            $("a.exit").toggleClass(".btn-salir-activado");
        });
    });
    */


 


//------------------------para que el boton CTA funcione en movil sin hover, toggle class------------



/*
    $('A.button-cta').click(function(event) {
        if (this).hasClass('active-button') {  

        } else {  
            event.preventDefault(); 
		$(this).toggleClass('active-button');   
        } 
        
    });	 
*/

//  ------------------------- FAMILIA PRODUCTO: Buton ver mas subfamilias producto --------------------------

    $("A.ver-mas").on("click", function(event) {
        event.preventDefault();
        $("DIV.type").removeClass("oculto");
        $(this).parent().remove();
    });

//  ------------------------- BESA LAB FORMATO VIDEOS: Buton cargar mas besa lab videos--------------------------


    $(".action-cargar-besa-lab-videos").on("click", function(event) {
        event.preventDefault();
        $(".type").removeClass("oculto");
        $(this).parent().remove();
    });    

});